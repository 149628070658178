import { HTTP } from "@/service/axios";

export default {
  FETCH_LIMITED_PRICE: async ({ commit }, obj) => {
    await HTTP.get(`price`)
      .then(res => {
        if (res.data.success) {
          commit("SET_LIMITED_PRICE", res.data.obj);
        }
      })
      .catch(e => {
        console.log("FETCH_LIMITED_PRICE error",e.response);
      });
  },
  FETCH_PRICE_PRODUCT: async ({ commit }, obj) => {
    await HTTP.get(`price/product/${obj.round}`)
      .then(res => {
        if (res.data.success) {
          commit("SET_PRICE_PRODUCT", res.data.obj);
        }
      })
      .catch(e => {
        console.log("FETCH_LIMITED_PRICE error",e.response);
      });
  },
  FETCH_GROUP_ALL_DATA: async ({ commit }, obj) => {
    await HTTP.get(`room/${obj.roomId}/group/${obj.groupId}`)
      .then(res => {
        
        if (res.data.success) {
          commit("SET_GROUP_ALL_DATA", res.data.obj);
        }
      })
      .catch(e => {
        console.log("FETCH_GROUP_ALL_DATA error",e.response);
      });
  },
  FETCH_FX_RATE: async ({ commit }, obj) => {
    let array = []
    for (let round = 1; round <= 6; round++) {
      await HTTP.get(`fx/${round}`)
      .then(res => {
        if (res.data.success) {
          array.push(res.data.obj)
        }
      })
      .catch(e => {
        console.log("SET_FX_RATE error",e.response);
      });
    }

    commit("SET_FX_RATE", array);

  },
  FETCH_SEARCH_ROOM: async ({ commit }, obj) => {
    await HTTP.post(`/search/room/`,obj)
      .then(res => {
          commit("SET_ROOM", res.data.obj);
      })
      .catch(e => {
        console.log("SET_ROOM error",e.response);
      });
  },
  FETCH_GROUPS: async ({ commit }, obj) => {
    await HTTP.get('/groups/'+obj.number)
      .then(res => {
        if (res.data.success) {
          let data ={
            label:'groups',
            value:res.data.obj.groups
          }
          commit('SET_ROOMDATA',data)
        }
      })
      .catch(e => {
        console.log("SET_ROOM error",e.response);
      });
  },
  updateCurrent: async ({ commit }, item) => {
      let data = {
        currentRound: item.currentRound,
        current: item.current
      }
   
      await HTTP.put('/current/' + item._id, data).then(res => {
      
        if (res.data.success) {

          let update = [{
            label:'current',
            value:res.data.obj.current
          },{
            label:'currentRound',
            value:res.data.obj.currentRound
          }]
          update.map(item =>{
            commit('SET_ROOMDATA',item)
          })
        }
      })
  },
};


