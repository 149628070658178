import Vue from "vue";
import Router from "vue-router";
import store from "./store";

Vue.use(Router);

const ifAuthenticated = (to, from, next) => {
  if (
    typeof store.state.user === "undefined" ||
    store.state.user === null ||
    store.state.user === "" ||
    store.state.user === null
  ) {
    next("/sign-in");
  } else {
    next();
  }
};

const ifUserInGame = (to, from, next) => {
  if (
    typeof store.state.user === "undefined" ||
    store.state.user === null ||
    store.state.user === "" ||
    store.state.user === null ||
    typeof store.state.user.userLevel === "undefined"
  ) {
    next("/sign-in");
  } else {
    next();
  }
};

const router = new Router({
  mode: "history",
  routes: [
    { path: "*", component: () => import("@/views/ErrorScreen") },
    {
      path: "/",
      redirect: "/sign-in",
    },
    {
      path: "/sign-in",
      name: "sign in",
      component: () => import("@/views/SignIn"),
    },
    {
      path: "/sign-up/:level?",
      name: "sign up",
      component: () => import("@/views/SignUp"),
    },
    {
      path: "/join",
      name: "join",
      component: () => import("@/views/StartGame"),
    },
    {
      path: "/start",
      name: "start",
      component: () => import("@/views/SetTeamName"),
    },
    {
      path: "/select-mode",
      name: "selectMode",
      beforeEnter: ifUserInGame,
      component: () => import("@/views/SelectMode"),
      meta: {
        requiresAuth: true,
        adminAuth: false,
        userAuth: true,
      },
    },
    {
      path: "/random-payment",
      name: "randomPayment",
      component: () => import("@/views/RandomPayment"),
    },
    {
      path: "/random-event-card",
      name: "randomEventCard",
      component: () => import("@/views/RandomEventCard"),
    },
    {
      path: "/waiting",
      name: "waiting",
      component: () => import("@/views/WaitingPlayer"),
      meta: {
        requiresAuth: true,
        adminAuth: false,
        userAuth: false,
      },
    },
    {
      path: "/create-room",
      name: "create room",
      beforeEnter: ifAuthenticated,
      component: () => import("@/views/CreateRoom"),
      meta: {
        requiresAuth: true,
        adminAuth: true,
        userAuth: false,
      },
    },
    {
      path: "/lobby-admin",
      name: "lobby admin",
      beforeEnter: ifAuthenticated,
      component: () => import("@/views/LobbyAdmin"),
      meta: {
        requiresAuth: true,
        adminAuth: true,
        userAuth: false,
      },
    },
    {
      path: "/payment-term",
      name: "payment term",
      meta: {
        requiresAuth: true,
        adminAuth: true,
        userAuth: false,
      },
      component: () => import("@/views/PaymentTermInput"),
    },
    {
      path: "/waiting-sale-order",
      name: "waiting sale order",
      meta: {
        requiresAuth: true,
        adminAuth: true,
        userAuth: false,
      },
      component: () => import("@/views/WaitingSellOrder"),
    },
    {
      path: "/waiting-financial-product",
      name: "waiting financial product",
      meta: {
        requiresAuth: true,
        adminAuth: true,
        userAuth: false,
      },
      component: () => import("@/views/WaitingFinancial"),
    },
    {
      path: "/update-event-card",
      name: "business event card",
      meta: {
        requiresAuth: true,
        adminAuth: true,
        userAuth: false,
      },
      component: () => import("@/views/BusinessEventCard"),
    },
    {
      path: "/ac-inventory-adjust",
      name: "ac inventory adjust",
      component: () => import("@/views/AccountInventoryAdjust"),
    },
    {
      path: "/waiting-investment",
      name: "waiting investment",
      meta: {
        requiresAuth: true,
        adminAuth: true,
        userAuth: false,
      },
      component: () => import("@/views/WaitingInvestment"),
    },
    {
      path: "/players",
      name: "player",
      component: () => import("@/views/Players"),
    },
    {
      path: "/world-demand",
      name: "world demand",
      meta: {
        requiresAuth: true,
        adminAuth: true,
        userAuth: false,
      },
      component: () => import("@/views/WorldDemand"),
    },
    {
      path: "/sold-quantity",
      name: "sold quantity",
      meta: {
        requiresAuth: true,
        adminAuth: true,
        userAuth: false,
      },
      component: () => import("@/views/SoldQ"),
    },
    {
      path: "/leader-board",
      name: "leaderBoard",
      component: () => import("@/views/LeaderBoard"),
    },
  ],
});

router.beforeEach((to, from, next) => {
  const authUser = JSON.parse(window.localStorage.getItem("exsim"));
  console.log("To : ", to, " : Auth :", authUser);
  if (to.meta.requiresAuth) {
    if (!authUser || typeof authUser.user == null) {
      console.log("authUser", authUser.user);
      console.log("level", authUser.user.userLevel);
      next("/sign-in");
    } else if (to.meta.adminAuth && authUser.user) {
      console.log("admin", authUser.user.userLevel);
      if (authUser.user.userLevel == 80) {
        next();
      } else {
        console.log("admin else");
        next("/sign-in");
      }
    } else if (to.meta.userAuth && authUser.user) {
      console.log("user", authUser.user.userLevel);
      if (authUser.user.userLevel == 0) {
        next();
      } else {
        console.log("user else");
        next("/sign-in");
      }
    } else {
      console.log("else ", authUser.user);
      next();
    }
  } else {
    console.log("no requiresAuth");
    next();
  }
});

export default router;
