<template>
  <div id="app">
     <router-view :key="$route.fullPath"/>
  </div>
</template>

<script>

export default {
  name: 'app',
    sockets: {
        connect() {
            console.log('socket connected')
        },
        destroyed() {
          console.log('socket destroyed')
        },
    },
}
</script>



