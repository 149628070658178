import Cookies from 'js-cookie'
import { setToken, removeToken } from '@/service/auth'
import initState from './initState';

export default {
  SET_USER: (state, payload) => {
    state.user = payload
  },
  SET_DATAUSER: (state, payload) => {
    state.user[payload.label] = payload.value
  },
  SET_ROOM: (state, payload) => {
    state.room = payload
  },
  SET_ROOMDATA: (state, payload) => {
    state.room[payload.label] = payload.value
  },
  SET_TOKEN: (state, payload) => {
    setToken(payload)
    state.token = payload
  },
  SIGNOUT: (state) => {
    const initial = initState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
  SET_LIMITED_PRICE: (state, payload) => {
    state.limitedPrice = payload
  },
  SET_GROUP_ALL_DATA: (state, payload) => {
    state.groupAllData = payload
  },
  SET_FX_RATE:(state,payload)=>{
    state.fxRate = payload
  },
  SET_FINAN_PRODUCTS:(state,payload)=>{
    state.finanProducts = payload
  },
  SET_PRICE_PRODUCT:(state,payload)=>{
    state.priceProduct = payload
  },
  SET_RENDER_ANIMATE:(state,payload)=>{
    state.renderAnimate[payload.key] = payload.value
  },
  SET_DATA_ROUND_MODAL:(state,payload)=>{
    state.openModalDataRound = payload
  }
};
