import Vue from 'vue'
import ElementUI from 'element-ui';

import './element-variables.scss'
import 'swiper/dist/css/swiper.css'
import '@/styles/index.scss'

import App from './App.vue'
import router from './router';
import store from './store';
import locale from 'element-ui/lib/locale/lang/en'
import moment from "moment"
import momentTH from 'moment/src/locale/th' 
import io from 'socket.io-client';
import VueSocketio from 'vue-socket.io-extended';
import VueAwesomeSwiper from 'vue-awesome-swiper'


Vue.config.productionTip = false
Vue.use(moment);
Vue.use(VueSocketio, io(process.env.VUE_APP_BASE_URL));
Vue.use(VueAwesomeSwiper)

Vue.filter('timeTh', (date) => {
  let res = '';
  if (typeof date === 'undefined' || date === null || date === '') {
    res = '-';
  } else {
    res = moment(moment(date).locale('th', momentTH).add(543,'y')).format('LTS น.');
  }
  return res;
});

Vue.filter('comma', (value) => {
  if (typeof value === 'undefined' || value === null || value === '' || value === 0) {
    return 0;
  }else{
    value = value.toFixed(2);
    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }
 
});

Vue.filter('commaNoDot', (value) => {
  if (typeof value === 'undefined' || value === null || value === '' || value === 0) {
    return 0;
  }else{
    value = value.toFixed(0);
    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }
 
});

Vue.use(ElementUI, { locale });
new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
