export default {
    getMembers(state){
        if (state.members !== null) {
            let members = []
            state.members.admins.map((item)=>{
                item['role'] = 'admin'
                members.push(item)
            })

            state.members.members.map((item)=>{
                item['role'] = 'member'
                members.push(item)
            })
            
            return members
        }else{
            return []
        } 
    },
};
