
const initState = () => ({
  user:null,
  limitedPrice:null, 
  room:null,
  groupAllData:null,
  fxRate:[],
  finanProducts:null,
  priceProduct:null,
  renderAnimate:{
    branding:true,
    rd:true,
    paymentTerm:true
  },
  openModalDataRound:false,
  cardData:[
      {
          cardId : 0,
          Detail : "เศรษฐกิจคงตัว ดำเนินกิจการได้ตามปกติไม่มีเหตุการณ์ใดๆ กระทบกับธุรกิจ",
          Action : "ไม่มีการดำเนินการใดๆ"
      },{
          cardId: 1,
          Detail: "พนักงานจัดเตรียมเอกสารของ บริษัทส่ง B/L ให้ผู้นําเข้าใน ประเทศ B โดยระบุชื่อผู้นําเข้า เป็นผู้รับสินค้า ผู้นําเข้าเอา B/L ไปออกของแล้วไม่จ่ายเงิน",
          Action: "เอายอดขายสินค้าทั้งหมดของประเทศ B ในรอบปัจจุบัน ลบกับยอดขายสินค้าทั้งหมด ยกเว้นซื้อประกัน จะได้รับการยกเว้น ยกเว้นได้ Payment Term เป็น Adv ผู้ประกอบการจะได้รับเงินตาม % ของ Adv นั้นๆ นอกเหนือจากนี้ ผู้ประกอบการไม่ได้รับเงิน",
      },{
          cardId: 2,
          Detail: "ผู้นําเข้าในประเทศ B มีปัญหา บริษัทขาดสภาพคล่อง เจรจา ประนอมหนี้แล้ว ขอเลื่อนจ่ายเงิน เป็นรอบสุดท้าย",
          Action: "เอายอดขายสินค้าทั้งหมดของประเทศ B ในรอบปัจจุบัน ยกยอดไปเป็นลูกหนี้การค้ารอบที่ 6 ยกเว้นได้ Payment Term เป็น Adv ผู้ประกอบการจะได้รับเงินตาม % ของ Adv นั้นๆ ของยอดขายสินค้าทั้งหมดของประเทศ B ในรอบปัจจุบัน นอกเหนือจากนี้ ผู้ประกอบการจะได้รับเงินจากประเทศ B ในรอบที่ 6",
      },{
          cardId: 3,
          Detail: "ผู้นําเข้าในประเทศ A ปฏิเสธ การชําระเงิน เนื่องจากสินค้าเทียบท่า ช้ากว่ากําหนดเวลาเลย จําเป็นต้องลดราคาสินค้าให้ 10%",
          Action: "เอายอดขายสินค้าทั้งหมดของประเทศ A ในรอบปัจจุบัน ลบ 10% ยกเว้นได้ Payment Term เป็น Adv ผู้ประกอบการจะได้รับเงินตาม % ของ Adv นั้นๆ ของยอดขายสินค้าทั้งหมดของประเทศ A ในรอบปัจจุบัน",
      },{
          cardId: 4,
          Detail: "ผู้นําเข้าในประเทศ B ปฏิเสธ การชําระเงิน เนื่องจากสินค้าเทียบท่า ช้ากว่ากําหนดเวลา เลยจําเป็นต้อง ลดราคาสินค้าให้ 20%",
          Action: "เอายอดขายสินค้าทั้งหมดของประเทศ B ในรอบปัจจุบัน ลบ 20% ยกเว้นได้ Payment Term เป็น Adv ผู้ประกอบการจะได้รับเงินตาม % ของ Adv นั้นๆ ของยอดขายสินค้าทั้งหมดของประเทศ B ในรอบปัจจุบัน",
      },{
          cardId: 5,
          Detail: "ผู้นําเข้าในประเทศ A ต่อรอง ให้ลดราคา เนื่องจากราคาตลาด ลดลงอย่างรวดเร็ว ผู้ส่งออกเห็น ว่าทําการค้ากันมานานจึงยอม ลดราคาสินค้าให้ 20%",
          Action: "เอายอดขายสินค้าทั้งหมดของประเทศ A ในรอบปัจจุบัน ลบ 20% ยกเว้นได้ Payment Term เป็น Adv ผู้ประกอบการจะได้รับเงินตาม % ของ Adv นั้นๆ ของยอดขายสินค้าทั้งหมดของประเทศ A ในรอบปัจจุบัน",
      },{
          cardId: 6,
          Detail: "ผู้นําเข้าในประเทศ A ที่ค้าขาย กันมานาน ทําธุรกิจขาดทุนและถูก ฟ้องล้มละลายก่อนส่งมอบของ ต้องยอมขายให้ผู้นําเข้ารายอื่นโดย ลดราคาสินค้าให้ 30%",
          Action: "เอายอดขายสินค้าทั้งหมดของประเทศ A ในรอบปัจจุบัน ลบ 30% ยกเว้นได้ Payment Term เป็น Adv ผู้ประกอบการจะได้รับเงินตาม % ของ Adv นั้นๆ ของยอดขายสินค้าทั้งหมดของประเทศ A ในรอบปัจจุบัน",
      },{
          cardId: 7,
          Detail: "ผู้นําเข้าในประเทศ B ปฏิเสธ การชําระเงิน เนื่องจากเกิดภาวะ ความไม่สงบทางการเมือง และมีการปิดท่าเรือจึงไม่สามารถเอา ของออกได้ เลยจําเป็นต้อง ลดราคาสินค้าให้ 50%",
          Action: "เอายอดขายสินค้าทั้งหมดของประเทศ B ในรอบปัจจุบัน ลบ 50% ยกเว้นได้ Payment Term เป็น Adv ผู้ประกอบการจะได้รับเงินตาม % ของ Adv นั้นๆ ของยอดขายสินค้าทั้งหมดของประเทศ B ในรอบปัจจุบัน",
      },{
          cardId: 8,
          Detail: "สินค้าคงคลังในโกดังเสียหาย 10% เนื่องจากมีน้ำซึมเข้ามาในโกงดัง",
          Action: "Inventory ลดลง 10% ของ inventory ทั้งหมด",
      },{
          cardId: 9,
          Detail: "สินค้าคงคลังในโกดังเสียหาย 20% เนื่องจากมีน้ำซึมเข้ามาในโกงดัง",
          Action: "Inventory ลดลง 20% ของ inventory ทั้งหมด",
      },{
          cardId: 10,
          Detail: "พนักงานของผู้ส่งออกช่วยเหลือด้านเอกสาร การส่งออกและให้บริการเป็นอย่างดี ทำให้ถูกพูดถึงในด้านดีเมื่อมีการประชุมสมาคมผู้นำเข้า",
          Action: "Brand Awareness เพิ่ม 25%",
      },{
          cardId: 11,
          Detail: "ค้นพบเทคนิคการผลิตระหว่างการทำการผลิตสินค้า",
          Action: "R&D Level เพิ่มขึ้น 1 หน่วย",
      },{
          cardId: 12,
          Detail: "ค้นพบเทคนิคการผลิตระหว่างการทำการผลิตสินค้า",
          Action: "R&D Level เพิ่มขึ้น 0.5 หน่วย",
      },{
          cardId: 13,
          Detail: "บริษัทชนะการแข่งขันทำแผนธุรกิจ EXAC Idol จึงได้รับการสนับสนุนให้ไปออกงานแสดงสินค้าต่างประเทศ",
          Action: "ได้รับเงินสนับสนุนจากภาครัฐเพื่อทำ Brand Awareness เพิ่มเติม 2 ครั้ง",
      },{
          cardId: 14,
          Detail: "บริษัทมีการสร้างเทคโนโลยีอย่างต่อเนื่องจนเป็นที่ยอมรับ จึงได้รับเงินสนับสนุนจากภาครัฐเพื่อทำ R&D เพิ่มเติม",
          Action: "ได้รับเงินสนับสนุนจากภาครัฐเพื่อทำ R&D เพิ่มเติม 1 ครั้ง",
      },{
          cardId: 15,
          Detail: "พนักงานของผู้ส่งออกส่งเอกสารการส่งออกล่าช้า ผู้นำเข้าในประเทศ A จึงไม่สามารถออกของได้ตามเวลาที่กำหนดจึงยอม ลดราคาสินค้าให้ 20%",
          Action: "ลดราคาสินค้าของประเทศ A 20%",
      },{
          cardId: 16,
          Detail: "ธนาคารของผู้นำเข้าในประเทศ A มีปัญหาสภาพคล่อง และโดนฟ้องล้มละลาย เจรจากับผู้นำเข้าแล้วผู้นำเข้าตกลงจะชำระเงินให้ใหม่แต่ขอเลื่อนจ่ายเงินเป็นรอบสุดท้ายเนื่องจากต้องทำการฟ้องร้องกับธนาคารก่อน",
          Action: "ประเทศ A เลื่อนจ่ายไปรอบสุดท้าย",
      }
    ]
});

export default initState;
 